
import { Vue, Component } from "vue-property-decorator";
import * as ApiSystemServices from "@/api/helpers/ApiSystem";
import APIDrawer from "@/components/TheAPIDrawer.vue";
import { parseDate } from "@/utils/date-converter";
import { Count, Params } from "@/api/Gondor";
import Notify from "@/utils/notifications";
import APISystem from "@/models/APISystem";
import * as ReportServices from "@/api/helpers/Report";

interface JSONContainer {
  data: Record<string, any>;
  status: boolean;
}

interface NewParams extends Params {
  startDate?: Date;
  endDate?: Date;
}

interface SortableParams {
  column: any;
  prop?: string;
  order: null | "ascending" | "descending";
}

@Component({
  components: {
    APIDrawer,
  },
})
export default class ApiDetails extends Vue {
  readonly limitArray = [10, 25, 50, 100, 250, 500, 1000];
  readonly defaultParams: NewParams = {
    limit: 10,
    offset: 0,
    order: "createdAt:DESC",
  };

  readonly defaultSort = {
    prop: "createdAt",
    order: "descending",
  };

  paramsPing: NewParams = {
    ...this.defaultParams,
  };
  paramsConvertion: NewParams = {
    ...this.defaultParams,
  };
  paramsHistory: NewParams = {
    ...this.defaultParams,
  };
  currentPagePing = 1;
  currentPageConvertion = 1;
  currentPageHistory = 1;
  fullscreenLoading = false;
  loading = false;
  apiSystem = {} as ApiSystemServices.ApiSystem;
  convertionData: Count<ApiSystemServices.ApiSystemConvertion> = {
    count: 0,
    rows: [],
  };
  pingData: Count<ApiSystemServices.ApiSystemPing> = {
    count: 0,
    rows: [],
  };
  history: Count<ApiSystemServices.APIHistory> = {
    count: 0,
    rows: [],
  };

  dialogTitle = "";
  curentJSON: JSONContainer = {
    data: {},
    status: false,
  };
  visible = false;

  apiSystemDrawer = false;
  editableApiSystem = new APISystem();

  convertionDate = [];
  pingDate = [];
  historyDate = [];
  historyReport: any[] = [];

  dateString(date: string) {
    return parseDate(date, true);
  }

  setConvertionDates(dateRange: null | [Date, Date]) {
    if (Array.isArray(dateRange)) {
      this.paramsConvertion.startDate = dateRange[0];
      this.paramsConvertion.endDate = dateRange[1];
    } else {
      this.paramsConvertion.startDate = undefined;
      this.paramsConvertion.endDate = undefined;
    }
  }

  setPingDates(dateRange: null | [Date, Date]) {
    if (Array.isArray(dateRange)) {
      this.paramsPing.startDate = dateRange[0];
      this.paramsPing.endDate = dateRange[1];
    } else {
      this.paramsPing.startDate = undefined;
      this.paramsPing.endDate = undefined;
    }
  }

  setHistoryDates(dateRange: null | [Date, Date]) {
    if (Array.isArray(dateRange)) {
      this.paramsHistory.startCreatedAt = dateRange[0];
      this.paramsHistory.endCreatedAt = dateRange[1];
    } else {
      this.paramsHistory.startCreatedAt = undefined;
      this.paramsHistory.endCreatedAt = undefined;
    }
  }

  setTitle(name: "Peticion" | "Respuesta", apiName: string, leadId: number) {
    this.dialogTitle = `${name} ${apiName} ${leadId}`;
  }

  async downloadApiHistory(dates: [string | undefined, string | undefined]) {
    const [startDate, endDate] = dates;

    const report = await ReportServices.apiHistory(
      this.$route.params.id,
      startDate,
      endDate
    );
    this.historyReport = report.data.data;

    const content = this.historyReport[0].data;
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob([content]));
    link.setAttribute("download", this.historyReport[0].name);
    document.body.appendChild(link);
    link.click();
  }

  copyJSON(json: Record<string, any>) {
    const jsonString = JSON.stringify(json, null, 2);
    navigator.clipboard
      .writeText(jsonString)
      .then(() => {
        Notify.successful("Copiado en el portapapeles.");
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  sortTableConvertion({ column, prop, order }: SortableParams) {
    if (!order) {
      this.paramsConvertion.order = undefined;
    } else {
      const sorter = order === "ascending" ? `${prop}:ASC` : `${prop}:DESC`;
      this.paramsConvertion.order = sorter;
    }

    this.findConvertion(1);
  }

  sortTablePing({ column, prop, order }: SortableParams) {
    if (!order) {
      this.paramsPing.order = undefined;
    } else {
      const sorter = order === "ascending" ? `${prop}:ASC` : `${prop}:DESC`;
      this.paramsPing.order = sorter;
    }

    this.findPing(1);
  }

  sortTableHistory({ column, prop, order }: SortableParams) {
    if (!order) {
      this.paramsHistory.order = undefined;
    } else {
      const sorter = order === "ascending" ? `${prop}:ASC` : `${prop}:DESC`;
      this.paramsHistory.order = sorter;
    }

    this.findHistory(1);
  }

  handleDrawer(status: boolean) {
    this.apiSystemDrawer = status;
  }

  editApiSystem() {
    this.editableApiSystem = {
      id: this.apiSystem.id,
      active: this.apiSystem.active,
      leadOffer: this.apiSystem.leadOffer,
      redirectUrl: this.apiSystem.redirectUrl,
      apiId: {
        id: this.apiSystem.api.id,
        name: this.apiSystem.api.name,
        description: this.apiSystem.api.description,
        code: this.apiSystem.api.code,
        clientId: {
          id: this.apiSystem.api.client.id,
          name: this.apiSystem.api.client.name,
        },
        offerTypeId: {
          id: this.apiSystem.api.offerType.id,
          name: this.apiSystem.api.offerType.name,
        },
      },
      systemId: {
        id: this.apiSystem.system.id,
        name: this.apiSystem.system.name,
      },
      pingtree: this.apiSystem.pingtree,
    } as APISystem;
    this.handleDrawer(true);
  }

  findApiSystem() {
    this.fullscreenLoading = true;
    ApiSystemServices.findOne(this.$route.params.id)
      .then(res => {
        this.apiSystem = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.fullscreenLoading = false;
      });
  }

  findPing(page = this.currentPagePing) {
    this.loading = true;
    this.paramsPing.offset = (this.paramsPing.limit as number) * (page - 1);
    this.currentPagePing = page;

    ApiSystemServices.ping(this.$route.params.id, this.paramsPing)
      .then(res => {
        this.pingData = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  findConvertion(page = this.currentPageConvertion) {
    this.loading = true;
    this.paramsConvertion.offset =
      (this.paramsConvertion.limit as number) * (page - 1);
    this.currentPageConvertion = page;

    ApiSystemServices.convertion(this.$route.params.id, this.paramsConvertion)
      .then(res => {
        this.convertionData = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  findHistory(page = this.currentPageConvertion) {
    this.loading = true;
    this.paramsHistory.offset =
      (this.paramsHistory.limit as number) * (page - 1);
    this.currentPageHistory = page;

    ApiSystemServices.history(this.$route.params.id, this.paramsHistory)
      .then(res => {
        this.history = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  deleteApi() {
    ApiSystemServices.destroy(this.$route.params.id)
      .then(() => {
        this.$router.push({
          name: "api",
        });
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.visible = false;
      });
  }

  created() {
    const index = "10";
    const title = "Detalles";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: "API'S",
        to: {
          name: "api",
        },
      },
      {
        text: title,
      },
    ]);
  }

  mounted() {
    this.findApiSystem();
    this.findPing();
    this.findConvertion();
    this.findHistory();
  }
}

/** Converts a string date in ISO 8601 format to ´string´ dd/mm/yyy */
export const parseDate = (dateString: string, time = false): string => {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const year = date.getUTCFullYear();

  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");

  let parsedDate = `${day}/${month}/${year}`;

  if (time) {
    parsedDate += ` ${hours}:${minutes}:${seconds}`;
  }

  return parsedDate;
};
